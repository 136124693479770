@import '../../_variables.scss';

.prt-footer {
  height: 80px;
  background-color: #f7f7f7;
  color: #a1a1a1;
  font-size: 14px;

  .powered {
    display: block;
    margin:0;
  }

  .prt-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  a {
    color: #000;

    &:last-child {
      margin-left: auto;
    }
  }
}

.prt-legal {
  span {
    margin-right: 40px;
  }

  a {
    margin-right: 16px;
    padding-right: 20px;
    border-right: 1px solid #646464;

    &:last-child {
      border-right: 0;
      margin-right: 0;
      padding-right: 0;
    }
  }
}
