.prt-pagination {
  font-size: 16px;
  font-weight: 500;

  > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 50%;
    color: #828282;

    &:hover {
      background-color: $color-hover;
      color: $color-primary;
      text-decoration: none;
    }

    &.prt-pagination-prev {
      margin: 0 10px;
      font-size: 0;
      background: url(../../assets/arrow.svg) no-repeat 50%;
      transform: scaleX(-1);
    }

    &.prt-pagination-next {
      margin: 0 10px;
      font-size: 0;
      background: url(../../assets/arrow.svg) no-repeat 50%;
    }

    &.current {
      background-color: $color-primary;
      color: #fff;
      cursor: default;
    }

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }
}
