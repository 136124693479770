@keyframes moveSearchIcon {
    0% {
        border-color: rgba(228,228,228,1);
        left: calc(100% - 51px);
    }
    20% {
        border-color: rgba(228,228,228,0);
        padding:0;
    }
    100% {
        border-color: rgba(228,228,228,0);
        padding:0;
        left: 23px;
    }
}

@keyframes showSearch {
    0% {
        border-bottom: 1px solid rgba(228,228,228,0);
        opacity: 0;
        width: 0;
    }
    100% {
        border-bottom: 1px solid rgba(228,228,228,1);
        opacity: 1;
        width: calc(100% - 40px);
    }
}

@keyframes showClose {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes hidePlaceholder {
    0% {
        opacity: 1;
        left: 0px;
    }
    100% {
        opacity: 0;
        left: -100px;
    }
}

.SearchColumn {
    overflow: hidden;
    .search-close {
        cursor: pointer;
        display: none;
        position: absolute;
        right: 27px;
        top: 18px;
        visibility: hidden;
    }
    .search-control {
        border: none;
        width: 0;
        margin-left: -80px;
        font-size: 1em;
        line-height: 18px;
        margin: 0 20px;
        padding: 0;
    }
    .search-icon {
        border-left: 1px solid #e4e4e4;
        display: inline-block;
        padding: 0 0 0 10px;
        position: absolute;
        top: 18px;
        vertical-align: inherit;
    }
    .search-placeholder {
        margin-right: 15px;
        position: relative;
    }
    &.active {
        .search-icon {
            animation: moveSearchIcon .25s ease-in 0s forwards;
        }
        .search-placeholder {
            animation: hidePlaceholder .25s ease-in 0s forwards;
            position: absolute;
        }
        .search-close {
            animation: showClose .5s linear .25s forwards;
            display: block;
        }
        .search-control {
            animation: showSearch .5s ease-in .25s forwards;
        }
    }
}