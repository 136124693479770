.prt-text-field {
  &.error {
    border-color: #DD3B26;
  }
  & + sub {
    color: #DD3B26;
    display: inline-block;
    transform: translateY(-8px);
    transition: all 0.5s ease;
    opacity: 0;
    &.error {
      opacity: 1;
      transform: translateY(0);
    }
  }
}