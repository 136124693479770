@import '../../_variables.scss';
.prt-login {
  background-color: #dfdfdf;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-box {
    width: 640px;
    margin: auto;
    background-color: #fff;
    border-radius: $radius;
    box-shadow: 0 2px 5px rgba(0,0,0, 0.05);

    .prt-page-title {
      margin: 60px 0 30px;
      font-size: 30px;
    }
  }

  &-form {
    width: 340px;
    margin: auto;
    padding: 70px 0;
    text-align: center;

    > form {
      width: 270px;
      margin: 0 auto;
    }

    .prt-text-field {
      width: 100%;
      box-sizing: border-box;
    }

    .prt-form-field {
      margin-bottom: 10px;
      text-align: left;
    }

    .prt-remember-me {
      display: inline-block;
      margin: 15px 0 30px;
      font-size: 14px;
      color: #777;
      cursor: pointer;
    }

    &-pass {
      margin-top: 40px;
      display: inline-block;
      font-size: 15px;
      font-weight: 500;
    }
    .disabled {
      pointer-events: none;
      background-color: #ccc;
    }
  }
}
.centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}