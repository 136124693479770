@import "../../_variables.scss";

.prt-header {
  box-sizing: border-box;

  .prt-wrapper {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
  }

  .prt-nav {
    background: #004b87;
  }
}

.prt-logo {
  > * {
    max-height: 80px;
  }
}

.prt-account {
  font-weight: 500;
  cursor: pointer;
  user-select: none;

  &.open > .prt-account-dropdown {
    display: block;
  }

  &:hover {
    .prt-account-pic {
      background-color: #e3e3e3;
    }
  }

  &-pic {
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-left: 10px;
    background-color: #f0f0f0;
    border-radius: 50%;
    box-sizing: border-box;
    color: $color-primary;
    font-size: 15px;
  }

  &-dropdown {
    display: none;
    position: absolute;
    right: 0;
    top: 95px;
    z-index: 5;
    min-width: 300px;
    background-color: #fff;
    cursor: default;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    > span {
      display: block;
      font-weight: 400;
      padding: 15px 30px;
      border-bottom: 1px solid #e4e4e4;
    }

    > ul.prt-account-links {
      padding: 20px 0;
      > li {
        display: block;
        padding: 10px 30px;
        &:hover {
          background-color: $color-hover !important;
          color: $color-primary !important;
          cursor: pointer;
        }
      }
    }
  }
}

.prt-nav-list {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;

  > li {
    padding: 0;
    a {
      font-size: 13px;
      line-height: 16px;
      display: inline-block;
      text-transform: uppercase;
      text-decoration: none;
      padding: 22px 30px;
      color: rgba(255, 255, 255, 0.8);
      &:hover {
        opacity: 0.7;
      }
      &.active {
        background: rgba(0, 0, 0, 0.2);
        color: #fff;
        opacity: 1;
        font-weight: 500;
      }
    }
  }
}
