.ImportModal {
  .inner {
    // width: 570px;
    width: 100%;
  }

  .content {
    padding: 40px 50px;
    padding-bottom: 50px;
    position: relative;

    .importSuccess {
      color: green;
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 8px;
      display: inline-block;
    }

    .csv-upload-hint {
      display: block;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-bottom: 8px;
      > a {
        margin-left: 25px;
        color: #00bbe5;
        text-decoration: none;
        font-weight: 600;
      }
    }

    // .importAlert {
    //   color: #999;
    //   font-size: 12px;
    //   font-weight: 600;
    //   margin-bottom: 8px;
    //   display: inline-block;
    // }

    .csvSelector {
      border: 2px dashed #e3e3e3;
      display: flex;
      height: 160px;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      span {
        font-size: 14px;
        color: #000;

        &:first-child {
          color: #004b87;
          margin-right: 4px;
          font-weight: 600;
        }
      }
    }
  }

  .btn-primary {
    width: 180px;
  }

  .btn-secondary {
    border-width: 0;
    color: #9e9e9e;
  }
}
