@import "../../_variables";
@import "./_buttons";
@import "./_pagination";

html,
body,
#root {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font: 400 16px/1.5 $font-base;
  color: $color-text;
  margin: 0;
  min-width: 1280px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  th {
    text-align: left;
  }
}

.selector {
  width: 100%;
  margin-top: 1em;
  > span {
    display: none !important;
  }
  > span + div {
    background-color: white !important;
    border-color: $color-primary !important;
    box-shadow: none !important;
    outline: none !important;
  }
  > div {
    background: #f5f5f5 !important;
    border-width: 2px !important;
    border-color: transparent !important;
    padding: 5px 10px !important;
    font: 15px/18px $font-base !important;
    border-radius: 3px !important;
    &:last-child {
      padding: 0 !important;
    }
  }
}

.prt {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prt-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.prt-body {
  flex: 1;
  padding-bottom: 50px;
}

.prt-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0 20px;
}

$head-panel-height: 200px;

.prt-head-panel {
  height: $head-panel-height;
  background-color: #fbfbfb;
  border: 1px solid #e9e9e9;
  margin-top: -1px;

  &-inner {
    height: $head-panel-height;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .prt-page-title {
    margin-bottom: 30px;
  }
}

.prt-page-title {
  font-size: 40px;
  font-weight: 300;
}

.prt-actions {
  position: absolute;
  right: 0;
  top: -25px;
}

.prt-head-panel + .prt-wrapper {
  padding-top: 40px;
}

.prt-wrapper {
  max-width: 1200px;
  margin: auto;
  position: relative;
  z-index: 2;
}

.prt-logo {
  display: flex;
  align-items: center;
  > img {
    display: block;
  }
  > span {
    border-left: 1px solid #d6d6d6;
    color: $color-text;
    font-size: 13px;
    margin-left: 20px;
    padding-left: 20px;
    text-transform: uppercase;
    opacity: 0.5;
  }

  &:hover {
    text-decoration: none;
  }
}

a {
  text-decoration: none;
  color: $color-primary;
  &:hover {
    text-decoration: underline;
  }
}

p {
  &:last-child {
    margin: 0;
  }
}

button,
input {
  outline: none;
}

input[type="text"],
input[type="number"],
input[type="file"],
input[type="email"],
input[type="password"],
textarea,
div.prt-text-field {
  background: $color-input-bg;
  border: solid 2px transparent;
  border-radius: $radius;
  box-sizing: border-box;
  color: $color-text;
  width: 100%;
  font: 15px/18px $font-base;
  padding: 16px 20px;
  outline: none;
  &:focus {
    border-color: $color-primary;
  }
  &.error {
    border-color: $color-danger;
    & + p {
      font-size: 13px;
      color: $color-danger;
    }
  }
}

input[type="file"] {
  height: 52px !important;
}

.one-sixth {
  width: 16.66666%;
}
.one-fourth {
  width: 25%;
}
.one-third {
  width: 33.33333%;
}
.one-half {
  width: 50%;
}
.two-third {
  width: 66.66666%;
}
.three-fourth {
  width: 75%;
}
.full-width {
  width: 100%;
}
.prt-form-field {
  padding: 0 15px;
  &:last-child > button {
    margin-bottom: 15px;
    margin-left: 15px;
    margin-top: 30px;
    min-width: 200px;
    &.is-cancel {
      min-width: 100px;
    }
  }
}

.powered {
  width: 200px;
  margin: 0 auto;
}

.prt-form-cell {
  box-sizing: border-box;
  display: inline-block;
  padding: 15px;
  vertical-align: top;
}
form label {
  color: $color-text;
  display: block;
  font: 600 14px/17px $font-base;
  margin-bottom: 9px;
}

.mandatory::after {
  color: #dd3b26;
  content: "*";
  font-weight: 800;
  font-size: 18px;
  margin-left: 0.3em;
}
.accordeon {
  margin: 20px 30px;
  text-transform: uppercase;
  color: #004b87;
  letter-spacing: 0.03em;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  height: 15px;
  user-select: none;

  > span {
    background: #fff;
    padding: 0 10px;
    position: absolute;
    z-index: 10;
    font-weight: 600;
  }
  &::after {
    content: "";
    top: 50%;
    height: 1px;
    background: #e8e8e8;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-1px);
  }
}
