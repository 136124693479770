.NumberModal {
  .user-selector {
    > div:not([class$="-control"]) {
      background: #fff !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .select-option {
      padding: 11px 20px;
      background-color: #fff;
      font-size: 13px;
      color: #8d8d8d;
      cursor: pointer;
      &:hover {
        background-color: #e7f3fb;
      }
      label {
        font-size: 15px;
        color: #242424;
      }
    }
  }
  #phone_number_flag + span {
    position: absolute;
    height: 54px;
    color: #555;
    text-align: right;
    font: 15px/54px Helvetica Neue, Helvetica, Arial, sans-serif;
    left: 84px;
  }
  .prt-form-cell section {
    display: flex;
    flex-grow: 1;
    position: relative;
  }
  .prt-form-cell {
    vertical-align: top;
  }
  .prt-form-cell section input {
    padding-left: 110px;
  }
}