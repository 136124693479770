@import "../../_variables.scss";

.reportContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .reportDiagram {
        position: relative;
        width: 70%;
    }

    .reportStat {
        position: relative;
        width: 30%;
    }

    .dataContainer {
        border: solid 1px #dfdfdf;
        margin: 10px 5px;
        border-radius: 20px;
        height: 100%;
    }

    .reportData {
        padding: 20px;
        height: 100%;
    }

    .row {
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        margin-bottom: 10px;

        .column {
            display: inline-block;
            text-align: center;
            width: 50%;

            h5 {
                color: #7a7a7a;
                font: 400 14px/17px "Helvetica Neue", Helvetica, Arial, sans-serif;
                margin: 15px 0 0 0;
            }

            span {
                color: #242424;
                font: 300 36px/44px "Helvetica Neue", Helvetica, Arial, sans-serif;
                margin-bottom: 15px;
                display: block;
            }
        }
    }

    .row:hover,
    .active {
        background-color: #f6f6f6;
    }

    .row:last-child {
        margin-bottom: 0px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dfdfdf;
        padding: 0px 20px 20px 20px;
        align-items: center;

        header {
            font: bold 25px/50px "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .wd70 {
            width: 70%;
        }

        .legend {
            display: flex;
            width: 50%;
            .inbound,
            .outbound {
                display: block;
            }

            .inbound::before,
            .outbound::before {
                border-radius: 50%;
                content: "";
                height: 11px;
                margin-right: 6px;
                width: 11px;
                display: inline-block;
            }

            .inbound::before {
                background: #004b87;
            }

            .outbound::before {
                margin-left: 30px;
                background: #ed8b00;
            }
        }

    }

    .mg20 {
        margin: 20px;
    }

    .diagramContainer {
        display: flex;
        align-items: center;

        .barChart {
            // width: 100%;
        }

        .barTitle {
            padding: 10px 10px 10px 20px;
            border-right: 1px solid #dfdfdf;
            color: #707070;
            font: 15px/30px "Helvetica Neue", Helvetica, Arial, sans-serif;
            width: 100px;
            line-height: 18px;
            text-align: center;
        }

        .subHeading {
            text-align: center;
        }

        .total {
            padding: 0px 20px;
            color: #000;
            font: bold 15px/30px "Helvetica Neue", Helvetica, Arial, sans-serif;
        }

        .barInbound,
        .barOutbound {
            height: 10px;
        }

        .barInbound {
            background: #004b87;
        }

        .barOutbound {
            background: #ed8b00;
        }

        .barChart {
            display: flex;
            align-items: center;
            padding-left: 20px;
            cursor: pointer;
        }

        .chart {
            display: flex;
            align-items: center;
            width: calc(100% - 100px);
        }
    }

    .diagramBox {
        min-height: 400px;
        max-height: 400px;
        overflow-y: auto;
    }

    .no-data {
        padding: 30px 20px;
        text-align: center;
        color: #7a7a7a;
        font: 400 18px/17px "Helvetica Neue", Helvetica, Arial, sans-serif;
        text-align: center;
    }
}


.filterPanel {
    display: grid;
    grid-template-columns: 130px 130px 1fr 130px;
    grid-gap: 20px;
    width: 80%;
    // align-items: center;
    margin-left: 20px;
}

.selector {
    margin-top: 0px !important;
    min-height: 54px;
}

.selector>div:first-child {
    min-height: 54px;
}

.labelC {
    label {
        margin-left: 5px;
        font-weight: bold;
    }
}

.filterButton {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.statPanel {
    border: none !important;
    display: flex;
    flex-direction: column;
    gap: 20px;

    >div {
        border: solid 1px #dfdfdf;
        border-radius: 20px;
    }

    .downloadSection {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .download-cursor {
            cursor: pointer;
        }

        .message-dropdown {
            border: 0;
            -webkit-appearance: none;
            padding: 10px;
            margin-left: 5px;
        }
    }
}

.relative {
    position: relative;
}

.helpIcon {
    position: absolute;
    right: 3px;
    cursor: pointer;
}

.tooltipContainer {
    max-width: 250px;
}