@import "../../_variables.scss";

.table-control {
    td {
        max-width: 300px;
        padding: 25px 5px !important;
    }

    th {
        padding: 15px 5px !important;
    }
}
