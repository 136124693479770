@import '../../_variables.scss';

.addCustomerBtn {
  float: right;
  margin-top: -65px;
}
.prt-tabs {
  display: flex;
  position: relative;
  top: 1px;

  li {
    margin-right: 40px;
    font-size: 13px;
    text-transform: uppercase;

    &.active {
      font-weight: 500;

      a {
        opacity: 1;
        cursor: default;
      }
    }
  }

  a {
    display: block;
    position: relative;
    padding-bottom: 22px;
    text-decoration: none !important;
    color: $color-text;
    opacity: 0.5;
    transition: opacity 0.4s;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      display: block;
      height: 3px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}



.prt-info {
  &-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
  &-label {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #989898;
  }
  &-value {
    font-size: 18px;
  }
}

// section header
.prt-group-header {
  display: flex;
  align-items: center;
  color: #828282;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 40px 0;

  &.invisible {
    margin: 0;
    padding: 20px 0;
  }

  &::after {
    content: '';
    display: block;
    margin-left: 10px;
    height: 1px;
    background-color: #e8e8e8;
    flex: 1;
  }

   > a {
     display: inline-flex;
     align-items: center;

     &:hover {
       text-decoration: none;
       color: lighten($color-primary, 5%);
     }

     &::before {
       content: '+';
       display: inline-block;
       width: 20px;
       height: 20px;
       font-size: 22px;
       font-weight: 300;
       line-height: 14px;
     }

     &.is-expanded {
       &::before {
         content: '\2013';
       }
     }
   }
}
