$font-base: "Helvetica Neue", sans-serif;

// colors
$color-primary: #004b87;
$color-primary-lighten: #00bbe5;
$color-text: #242424;
$color-dark: #1c1c1c;
$color-hover: #e7f3fb;
$color-danger: #dd3b26;
$color-input-bg: #f5f5f5;

// round corners
$radius: 3px;
$radius-big: 30px;
