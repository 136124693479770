.preview-buttons {
  margin-bottom: 30px;
  text-align: center;
}

label[for="invitation_email_text"] + .ThemedInput,
label[for="cc_invitation_email_text"] + .ThemedInput {
  margin-bottom: 1em;
}

.ThemedInput > textarea {
  height: 200px;
  max-height: 300px;
  max-width: 100%;
  min-height: 100px;
  min-width: 100%;
}

#welcome_message {
  height: 100px !important;
}

#logo_to_upload {
  max-height: 74px;
}

#logo_reset_btn {
  background: #fff;
  vertical-align: top;
  padding: 0;
  border: 0;
  margin-left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 1px 4px #ddd;
  margin-top: -13px;
  .icon-close::before {
    font-size: 22px;
    font-weight: 300;
    top: -2px;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 0;
  }
}

.CustomizeModal form {
  .partnerType {
    position: absolute;
    top: 52px;
    right: 90px;;
    width: 160px;
  }
  label[for="show_terms_of_use_link"] {
    margin-left: 85px;
  }
  label[for="show_help_link"] {
    margin-left: 30px;
  }
  .prt-tabs {
    float: right;
    li:last-child {
      margin-right: 0;
    }
    a {
      padding-bottom: 0;
    }
  }
  .email_preview {
    header {
      border-top: solid 1px #e5e5e5;
      padding: 1em 0 1em !important;
      &:before {
        content: "Subject: ";
        color: #b6b6b6;
        margin-right: 2em;
      }
    }
    > div {
      box-shadow: 0 6px 18px 0px #ddd;
      padding: 3em 6em;
    }
  }
  small {
    background: #ffeecc;
    display: block;
    padding: 16px 20px;
  }
  pre {
    font: inherit;
    padding: 0;
    white-space: pre-line;
    background: transparent;
  }
  label[for="show_logo_cc"] {
    margin-left: 11px;
  }
  label[for="show_logo_mv"] {
    margin-left: 35px;
  }
}

.preview {
  box-shadow: 0px 2px 8px 1px #ccc;
  margin: 40px 60px 0;

  &_header {
    color: #121212;
    position: relative;
    text-align: center;
    font: 600 13px "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom: solid 1px #e3e3e3;
    max-height: 50px;
    height: 50px;
    > span {
      line-height: 50px;
    }
    > img,
    > svg {
      position: absolute;
      top: 0;
      left: 0;
      max-height: 30;
      height: 30px;
      margin: 10px 15px;
    }
  }

  > section {
    display: flex;
  }

  &_conversations {
    border-right: solid 1px #e3e3e3;
    min-width: 25%;

    > header {
      background-color: #fafafa;
      border-bottom: solid 1px #e3e3e3;
      padding: 15px 25px;
      > span {
        display: block;
        background-color: #dedede;
        height: 7px;
        &:last-child {
          margin-top: 10px;
          width: 66%;
        }
      }
    }

    > ul {
      padding: 15px 25px;
      list-style: square;
      color: #dedede;
      > li {
        background-color: #dedede;
        height: 7px;
        margin-bottom: 10px;
        margin-left: 21px;
        line-height: 0.3;
        font-size: 22px;
      }
    }
  }

  &_chat {
    width: 100%;
    display: flex;
    flex-direction: column;
    > header {
      background-color: #fafafa;
      border-bottom: solid 1px #e3e3e3;
      padding: 15px 25px;
      > span {
        text-align: center;
        display: block;
        margin: 0 auto;
        background-color: #dedede;
        width: 100px;
        height: 7px;
        &:last-child {
          margin-top: 10px;
          width: 60px;
        }
      }
    }
    > section {
      padding: 30px 0;
      flex: 1;
      margin: 0 55px;
      > div {
        background: #004b87;
        border-radius: 0 10px 10px;
        height: 40px;
        margin-bottom: 15px;
        width: 50%;
        &.me {
          background: transparent !important;
          border: solid 2px #004b87;
          border-radius: 10px 0 10px 10px;
          float: right;
        }
      }
    }
    > footer {
      border-top: solid 1px #e3e3e3;
      padding: 10px 0;
      margin: 0 55px;
      ul {
        list-style: inside;
        > li {
          display: list-item;
          width: 25px;
          height: 35px;
          font-size: 50px;
          line-height: 30px;
          float: left;
        }
      }
      i {
        background-color: #004b87;
        float: right;
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }
  }

  &_sidebar {
    margin-top: -1px;
    min-width: 5.46%;
    max-width: 5.46%;
    ul {
      list-style: inside;
      font-size: 50px;
      line-height: 0.8;
      color: #fff;
      padding: 0 15px 112px;
    }
  }
}
