$radioSize: 20px;
$radioBorder: #dfdfdf;
$radioActive: #00bbe5;

.ThemedRadio {
  cursor: pointer;
  display: inline-block;
  margin: 16px 0;
  user-select: none;
  > input[type="radio"] {
    display: none;

    & + span {
      color: #242424;
      line-height: $radioSize;
      height: $radioSize;
      padding-left: $radioSize;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: $radioSize + 8;
      }
      &:before,
      &:after {
        border: solid 10px;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        width: $radioSize;
        height: $radioSize;
        display: block;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        background: #fff;
        border: solid 2px $radioBorder;
        transition: border-color .2s ease-out;
      }
      &:after {
        transform: scale(0);
        transition: transform .2s ease-in;
      }
    }
    &:checked + span {
      &:before {
        border-color: inherit;
      }
      &:after {
        transform: scale(.6);
        transition: transform .3s ease;
      }
    }
  }
}