.api-page {
    @import "~bootstrap/scss/bootstrap";
    .heading1 {
        font-size: 23px !important;
        margin-bottom: 0px !important;
    }
    position: relative;
    padding-left: 225px;
    height: calc(100vh - 62px);
    padding-top: 62px;
    background: #f0f0f0;
    .header {
        position: fixed;
        background: #fff;

        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: center;
        padding: 6px 0;
        border-bottom: 1px solid #d7d7d7;
        img {
            max-width: 100%;
            max-height: 46px;
        }
    }
    .sidebar {
        position: fixed;
        top: 62px;
        left: 0;
        width: 265px;
        height: calc(100% - 62px);
        overflow: auto;
        background: #004b87;
        padding: 10px 0;
        ul {
            padding-left: 0px !important;
            margin-bottom: 0px !important;
            li {
                display: block;
                button {
                    color: #fff;
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    position: relative;
                    background-color: #004b87;
                    border: none;
                    outline: none;
                    width: 100%;
                    svg {
                        position: absolute;
                        right: 15px;
                        transition: 0.2s ease-in-out;
                        transform: rotate(-90deg);
                    }
                    &.open {
                        svg {
                            transform: rotate(0deg);
                        }
                    }
                }
                a {
                    padding-left: 30px !important;
                    color: #fff !important;
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;
                    position: relative;
                    cursor: pointer !important;
                    svg {
                        position: absolute;
                        right: 15px;
                        transition: 0.2s ease-in-out;
                        transform: rotate(-90deg);
                    }
                    &:hover {
                        text-decoration: none;
                        cursor: pointer !important;
                        color: #fff !important;
                        opacity: 0.7;
                    }
                    & ~ ul {
                        display: none;
                    }
                    &.open {
                        svg {
                            transform: rotate(0deg);
                        }
                        & ~ ul {
                            display: block;
                        }
                    }
                    &.active {
                        background: rgba(0, 0, 0, 0.2);
                        opacity: 1;
                        font-weight: 500;
                    }
                }
                ul {
                    padding-left: 20px;
                }
            }
        }
    }
    main {
        padding: 0px 20px 0px 20px;
        margin-left: 30px;
        background: #f0f0f0;
        .panel-body {
            padding: 15px;
        }
        .panel-default > .panel-heading {
            color: #fff;
            background-color: #004b87;
            border-color: #004b87;
        }
        .panel-heading {
            padding: 10px 15px;
            border-bottom: 1px solid transparent;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }
        .panel {
            margin-bottom: 20px;
            background-color: #fff;
            border: 1px solid transparent;
            border-radius: 4px;
            -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        }
        p {
            margin: 0 0 10px;
        }

        section .pre-tag {
            color: #f0f0f0;
            background-color: #30363e;
        }

        .pre-tag {
            overflow: auto;
            display: block;
            padding: 5px;
            margin: 5px;
            font-size: 13px;
            line-height: 1.42857143;
            color: #333;
            word-break: break-all;
            word-wrap: break-word;
            background-color: #f5f5f5;
            border: 1px solid #ccc;
            border-radius: 4px;
            outline: #ccc solid 1px;
            // margin-left: 400px;
        }

        section pre {
            margin: 0 auto;
            // width: 400px;
            display: inherit;
            padding: 0;
            font-size: 15px;
            color: inherit;
            white-space: pre-wrap;
            background-color: transparent;
            border-radius: 0;
            margin-left: 400px;
            margin-right: 100px;
        }
        .__json-key__ {
            color: red;
        }
        .__json-string__ {
            color: green;
        }
        .__json-value__ {
            color: #ff8c00;
        }
        .__json-boolean__ {
            color: #00ced1;
        }
        .__json-pretty-error__ {
            color: #ff8c00;
            padding-top: 25px;
            padding-bottom: 25px;
            margin-left: 100px !important;
        }
    }
    section h3 {
        font-size: 18px;
        color: #004b87;
    }
    .table-responsive {
        min-height: 0.01%;
        overflow-x: auto;
    }
    .h3 {
        font-size: 18px !important;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
    }
    .imgtag {
        border: 1px solid #000;
        margin-bottom: 15px;
        img {
            width: 100%;
        }
    }
    .h4 {
        font-size: 15px !important;
        font-weight: 700 !important;
        color: #666 !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }
}
