.CustomizeModal {
  .inner {
    position: relative;
  }

  &:not(.previewMode) header {
    padding-bottom: 0 !important;
  }
  header {
    .prt-tabs a {
      font-weight: 400;
      padding: 0;
    }
  }

  .field-help {
    color: #828282;
    font: 13px/18px "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 10px;
  }
  .color-cell {
    vertical-align: top;
    .prt-text-field {
      height: 52px;
      > span {
        width: 18px;
        height: 18px;
        display: inline-block;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
      }
    }
    .color-cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }

    .color-wrapper {
      position: absolute;
      z-index: 2;
    }
  }
}

label[for="customize-logo-url1"],
label[for="customize-logo-url"] {
  margin-left: 35px;
}
