.container {
    padding: 20px;
}
.panel-section {
    margin-top: 15px;
}
.panel-title {
    font-size: 1.2em !important;
    margin-bottom: 10px;
    color: #333 !important;
}
.panel {
    border: 1px solid #d7d7d7;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-50 {
    width: 50%;
}
.panel-body {
    padding: 15px;
}
.info {
    margin: 0 0 10px !important;
    font-size: 15px;
}
.btn {
    margin-bottom: 15px;
    margin-top: 15px;
}
.btn-ouline-primary {
    border: 1px solid !important;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 45px 10px 45px;
    &:hover {
        text-decoration: none;
    }
}
.list {
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    h3,
    h4 {
        padding: 10px;
        margin: 0;
        background: #d7d7d7;
    }
    ul {
        list-style: none;
        margin: 0;
        padding-left: 25px;
        &.main-ul {
            padding: 0px 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
            overflow: hidden;
        }
        li {
            h4 {
                margin: 0 -20px;
            }
            a {
                display: block;
                padding: 10px 0px;
                border-bottom: 1px solid #d7d7d7;
                &:hover {
                    background: #e5e5e5;
                    text-decoration: none;
                }
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}
