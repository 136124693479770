.prt-form-field.password {
  position: relative;
  > input:focus + .ProfileAccount__PasswordRequirements {
    display: block;
  }
}
.prt-login-form {
  .errors {
    color: red;
    font-size: 12px;
    padding: 10px 0;
  }
}

.prt-login-form > .prt-logo {
  justify-content: center;
}

.ProfileAccount__PasswordRequirements {
  position: absolute;
  left: 110%;
  min-width: 250px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  background: #4B4B4B;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: #fff;
  font: 13px/19px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 20px;
  > h4 {
    margin: 0;
  }
  > ul {
    margin: 10px 0 0 0;
    padding: 0 0 0 20px;
    list-style: none;
    > li::before {
      content: "\2022";
      color: #c4c4c4;
      font-weight: bold;
      display: inline-block;
      width: 20px;
      margin-left: -16px;
      margin-right: -4px;
    }
    > li.active {
      list-style-image: url(../../../assets/tick.svg);
      &::before {
        content: '';
      }
    }
  }
  &:before {
    border-color: transparent #4b4b4b transparent transparent;
    border-style: solid;
    content: '';
    height: 0;
    margin-top: -6px;
    position: absolute;
    left: -4px;
    top: 50%;
    width: 0;
    border-width: 6px 4px 6px 0;
  }
}