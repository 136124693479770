.prt-companyUsers {
  &.open > .prt-account-dropdown {
    display: block;
    top: 35px;
    padding: 15px 30px;

    > span {
      color: #004b87;
      border-bottom-width: 0;
      padding: 0;
      line-height: 40px;
      font-weight: 500;
      font-size: 15px;
    }
  }
}

.add-button {
  display: flex;
  align-items: center;

  &::after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg);
    margin-left: 10px;
    margin-top: -5px;
  }
}
