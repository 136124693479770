@import "../../../_variables.scss";

.ConfirmModal > .inner > div {
  padding: 35px;
  text-align: center;
  > span.error {
    &:first-of-type {
      margin-top: 1em;
    }
    color: $color-danger;
    display: block;
  }
  > footer {
    margin-top: 35px;
  }
}