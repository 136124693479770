input[type='checkbox'] {
  height: 0;
  width: 0;
  display: none !important;
}
input[type='checkbox'] + label {
  position: relative;
  display: inline-block;
  margin: 0 0 20px;
  align-items: center;
  color: #686868;
  font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
  transition: color 250ms cubic-bezier(.4,.0,.23,1);
  line-height: 20px;
}
input[type='checkbox'] + label > span {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 1em;
  width: 20px;
  height: 20px;
  float: left;
  background: transparent;
  border: 2px solid #dfdfdf;
  border-radius: 2px;
  cursor: pointer;  
  transition: all 250ms cubic-bezier(.4,.0,.23,1);
}

input[type='checkbox'] + label:hover > span,
input[type='checkbox']:focus + label > span {
  background: rgba(255,255,255,.1);
}
input[type='checkbox']:checked + label > span {
  background: #00bbe5;
  border-color: #00bbe5;
  position: relative;
}
input[type='checkbox']:checked + label > span:before {
  content: "";
  position: absolute;
  top: 12px;
  right: 6px;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
  font-size: 18px;
}

@keyframes shrink-bounce{
  0%{
    transform: scale(1);
  }
  33%{    
    transform: scale(.85);
  }
  100%{
    transform: scale(1);    
  }
}
@keyframes checkbox-check{
  0%{
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: .2em;
    height: 0;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  100%{    
    width: .2em;
    height: .5em;    
    border-color: #fff;
    transform: translate3d(0,-.5em,0) rotate(45deg);
  }
}