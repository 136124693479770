.assignNumbers__description {
  font-size: 13px;
  line-height: 19px;
  color: #828282;
}

.error-msg {
  color: red;
  padding-left: 30px;
}

.UserForm {
  .selector {
    margin: 0 !important;
  }
}

.assignNumbers__getButton {
  background: #fff;
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 31px;
  display: block;
  font: bold 16px/50px "Helvetica Neue", Arial, Helvetica, sans-serif;
  text-align: center;
  color: #004b87;
  padding: 0;
  width: 100%;
  &:disabled {
    opacity: 0.5;
  }
}

.assignNumbers__header {
  margin: 10px 0 0 0;
}

.UserModal .prt-form-cell {
  vertical-align: top;
  section {
    display: flex;
    flex-grow: 1;
    position: relative;
    input {
      padding-left: 110px;
    }
  }
}

div.react-switch-bg {
  border-radius: 40px !important;
  i {
    font: normal 600 14px/40px Arial, sans-serif;
    color: #fff;
    display: block;
    height: 40px;
    text-align: center;
  }
}
