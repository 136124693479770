@import '../../../_variables.scss';

.modal {
  background: rgba(0,0,0,.55);
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;

  & + .prt {
    overflow: hidden;
    max-height: 100vh;
  }

  .inner > div {
    border-top: solid 1px #e8e8e8;
  }

  form {
    margin: 20px;
  }

  > .inner {
    align-self: center;
    background: #fff;
    box-sizing: border-box;
    margin: auto;
    width: 75%;
    max-width: 960px;
    display: flex;
    flex-direction: column;

    > ul {
      margin: -20px 0 0;
      list-style: none;
      border-bottom: solid 1px #e4e4e4;
      text-transform: uppercase;
      font: 13px $font-base;
      color: rgba(36,36,36,.4);
      min-height: 56px;
      padding: 0 30px;

      > li {
        display: inline-block;
        margin-left: 40px;
        cursor: pointer;
        padding: 20px 0;
        position: relative;
        font: 13px $font-base;
  
        &.active {
          color: #000;  
          font-weight: 500;
          &:before {
            height: 3px;
            background: $color-primary;
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
          }
        }
  
        &:first-child {
          margin-left: 0;
        }
      }
    }

    > section {
      display: flex;
      flex-grow: 1;
      padding: 0;
      justify-content: stretch;
    }

    > header {
      padding: 23px 40px;

  
      > h1 {
        color: #000;
        font: 300 30px/40px $font-base;
        margin: 0;
      }
  
      button {
        background: none;
        border: 0;
        color: #4c4c4c;
        cursor: pointer;
        font-size: 1.2em;
        height: 40px;
        line-height: 1;
        padding: 0;
        user-select: none;
        width: 40px;
        > i {
          display: block;
          height: 100%;
          line-height: 32px;
        }
        &.back-button {
          float: left;
          margin-right: 30px;
        }
        &.close-button {
          float: right ;
        }
      }
    }
  }
  .icon-close:hover:before {
    color: #000;
  }
  .icon-close:before {
    font-style: normal;
    color: #A2A2A2;
    font-size: 50px;
    font-weight: 100;
    content: "×";
  }
}