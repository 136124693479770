.OrderModal {
  .inner {
    width: 570px;
    .error-msg {
      color: red;
      padding-left: 20px;
    }
    .prt-form-cell {
      vertical-align: top;
    }
    .prt-form-field:last-child > button {
      margin-top: 0;
    }
  }
}

.inner {
  #phone_number_flag {
    width: 54px;
    border-radius: 3px;
    position: absolute;
    margin: 13px 15px;
    height: 28px;
    & + span {
      position: absolute;
      height: 54px;
      color: #555;
      text-align: right;
      font: 15px/54px Helvetica Neue, Helvetica, Arial, sans-serif;
      left: 84px;
    }
  }
}
