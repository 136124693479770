@import "../../_variables.scss";

$color-inactive: #e98510;
$color-active: #0ab625;
$color-closed: $color-text;
$color-archived: #838383;
$table-text-color: #707070;
$color-error: #dd3b26;

[class^="prt-status-"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  width: 100px;
  height: 25px;
  line-height: 25;
  box-sizing: border-box;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  pointer-events: none;
}

.prt-status-inactive,
.prt-status-draft,
.prt-status-pending,
.prt-status-submitted {
  color: $color-inactive;
}

.prt-status-expired,
.prt-status-failed,
.prt-status-cancelled,
.prt-status-rejected {
  color: $color-error;
}

.prt-status-active,
.prt-status-executed,
.prt-status-completed {
  color: $color-active;
}

.prt-status-closed,
.prt-status-partial {
  color: $color-closed;
}

.prt-status-archived {
  color: $color-archived;
}

.text-bold {
  font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}

.prt-table {
  width: 100%;
  margin-bottom: 40px;
  font-size: 14px;
  overflow: hidden;
  border-bottom: 1px solid #eee;

  a:not([class^="btn-action"]) {
    color: $color-text;
  }

  th {
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    padding: 15px 25px;
    position: relative;
    font-weight: bold;
    &::before,
    &::after {
      background: #fff;
      content: "";
      height: 100%;
      width: 10px;
      position: absolute;
      top: 0;
    }
    &::before {
      left: -5px;
    }
    &::after {
      right: -5px;
    }
    &.status {
      width: 100px;
    }
    &.date {
      width: 135px;
    }
  }

  td {
    padding: 12px 25px;
    color: $table-text-color;
    button.link {
      visibility: hidden;
      border: none;
      margin: 0;
      padding: 0 5px;
      width: auto;
      overflow: visible;
      background: transparent;
      text-transform: uppercase;
      color: #004b87;
      font-weight: 600;
      &.archive {
        color: #dd3b26;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &:hover {
      button.link {
        visibility: visible;
      }
    }
  }

  thead tr:first-child {
    position: relative;
  }

  tbody {
    tr {
      &:hover {
        background-color: $color-hover !important;

        .prt-status-inactive {
          background-color: $color-inactive;
          border-color: $color-inactive;
          color: #fff;
        }

        .prt-status-active {
          background-color: $color-active;
          border-color: $color-active;
          color: #fff;
        }

        .prt-status-closed {
          background-color: $color-closed;
          border-color: $color-closed;
          color: #fff;
        }

        .prt-table-actions {
          display: block;
        }

        a:not([class^="btn-action"]) {
          color: $color-primary;
        }

        td button.link {
          visibility: visible;
        }
      }

      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      &.is-archived {
        td {
          opacity: 0.5;
          &:last-child {
            opacity: 1;
          }
        }
      }
    }
  }
}

.prt-table-actions {
  display: none;
  text-align: right;
}

.prt-table-footer {
  display: flex;
  justify-content: flex-end;
}

.prt-table-filter {
  font-size: 14px;
  color: $table-text-color;
  a {
    margin-left: 10px;
  }
}

.prt-sortable {
  position: relative;

  > span {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:hover::after {
      color: $color-primary;
    }

    &::after {
      content: "";
      width: 5px;
      height: 5px;
      display: inline-block;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      margin-left: 8px;
      color: #bdbdbd;
    }
  }

  > ul {
    display: none;
    position: absolute;
    top: 8px;
    background-color: #fff;
    padding: 45px 0 15px;
    margin-left: -25px;
    margin-top: -20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 180px;
    border-radius: 3px;
    font-weight: 400;

    li {
      position: relative;
      padding: 7px 25px;
      cursor: pointer;

      &:hover {
        background-color: $color-hover;
      }

      &.current {
        display: flex;
        justify-content: space-between;
        align-items: center;
        opacity: 0.6;

        &::after {
          content: "";
          display: block;
          right: 25px;
          width: 10px;
          height: 5px;
          border-bottom: 2px solid;
          border-left: 2px solid;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
