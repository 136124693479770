@import "../../../../_variables.scss";

.dragndrop-container {
  position: relative;
  font-family: $font-base;
  margin-bottom: 30px;

  .innerArea {
    color: #666;
    border: 2px dashed #e3e3e3;
    height: 100%;
    width: 100%;
    display: table;

    &.hover {
      border-color: $color-primary;
    }
    &.table {
      border: none;
    }
    .placeholder {
      height: 160px;
      font-size: 14px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      padding: 10px 30px;
      overflow: auto;
      box-sizing: border-box;
    }
  }
  .upload-hint {
    font-size: 15px;
    text-align: center;
    padding: 10px;
    span {
      color: $color-primary;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }
  input[type="file"] {
    display: none;
  }
  .row {
    display: flex;
    .cell {
      border-left: 1px solid $color-primary-lighten;
      border-bottom: 1px solid $color-primary-lighten;
      padding: 4px 8px;
      width: 25%;
      overflow: hidden;
      text-align: left;
      &.th {
        font-weight: bold;
        padding: 10px 8px;
        color: #fff;
        background-color: $color-primary;
        text-align: center;
      }
    }
    .cell:last-child {
      border-right: 1px solid $color-primary-lighten;
    }
    .cell:first-child {
      font-weight: 600;
      flex-basis: 500px;
    }
    .cell:not(first-child) {
      font-size: 12px;
    }
  }
  .text-left {
    text-align: left;
  }

  .error-msg {
    padding: 0;
  }
}
