// primary button

%btn {
  display: inline-block;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  line-height: 50px;
  border-radius: $radius-big;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  text-align: center;
  background-color: $color-primary;
  padding: 0 40px;
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;

  &.full-width {
    display: block;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    background-color: lighten($color-primary, 3%);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.btn-primary {
  @extend %btn;
  color: #fff;

  &:hover {
    background-color: lighten($color-primary, 3%);
  }
}

.btn-secondary {
  @extend %btn;
  background-color: transparent;
  border: 2px solid #e0e0e0;
  color: $color-text;
  line-height: 46px;

  &:hover {
    border-color: #ccc;
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
    opacity: 0.9;
    outline: 1px dotted;
  }

  &.is-select {
    padding: 0 25px;
    display: inline-flex;
    align-items: center;

    &::after {
      content: '';
      width: 6px;
      height: 6px;
      margin-left: 8px;
      display: inline-block;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
    }
  }

  &.is-cancel {
    border: 0;
    color: #9e9e9e;

    &:hover {
      color: $color-text;
    }
  }

  &.is-text {
    border: 0;
    padding: 0;
  }
  &.is-danger {
    color: $color-danger;
  }
  &.is-primary {
    color: $color-primary;
  }
  &.is-right {
    float: right;
    min-width: 150px !important;
  }
}

// table action buttons
%action-btn {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-action-edit {
  @extend %action-btn;
  color: $color-primary;
}

.btn-action-archive {
  @extend %action-btn;
  color: $color-danger;
}

// back button
.prt-back {
  display: inline-block;
  display: inline-flex;
  font-weight: 500;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin-right: 7px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg);
  }
}
